import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Image1 = '../howItWorks/assets/Image1.png';
const Image2 = '../howItWorks/assets/Image2.png';
const Image3 = '../howItWorks/assets/Image3.png';
const Image4 = '../howItWorks/assets/Image4.png';

const howItWorksData = [
  {
    title: 'Tell us what you need help with',
    image: (
      <StaticImage
        src={Image1}
        placeholder="blurred"
        alt="Connecting with GoGetters"
      />
    ),
    points: [
      <>
        {' '}
        Save time with{' '}
        <span className="teal">
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/360005494133-I-have-repeated-jobs-every-week-Can-I-save-a-template-">
            job templates
          </a>
        </span>{' '}
        for repeated jobs{' '}
      </>,
      <>
        {' '}
        Connect with GoGetters with our{' '}
        <span className="teal">
          <a href="https://gogetmy.zendesk.com/hc/en-us/sections/202713928-How-It-Works">
            mobile app
          </a>
        </span>
        .{' '}
      </>
    ]
  },
  {
    title: 'Customise your requests',
    image: (
      <StaticImage
        src={Image2}
        placeholder="blurred"
        alt="GoGetter profile details"
      />
    ),
    points: [
      <>
        {' '}
        Hire{' '}
        <span className="teal">
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/360006765053-What-is-Favourite-GoGetter-">
            favourite GoGetters
          </a>
        </span>{' '}
        to do regular jobs.{' '}
      </>,
      <>
        {' '}
        Customise requests and screen profiles based on{' '}
        <span className="teal">
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/360019950494-How-does-GoGet-work-">
            reviews and ratings
          </a>
        </span>
        .{' '}
      </>
    ]
  },
  {
    title: 'We’ve built this for you',
    image: (
      <StaticImage
        src={Image3}
        placeholder="blurred"
        alt="GoGetter live tracking"
      />
    ),
    points: [
      <>
        {' '}
        <span className="teal">
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/360008095873-Can-I-track-my-GoGetter-during-deliveries-">
            Keep track
          </a>
        </span>{' '}
        of your GoGetters in real time with status updates.{' '}
      </>,
      <>
        {' '}
        <span className="teal">
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/360002246274-How-will-the-GoGetter-know-if-my-items-can-fit-in-his-her-vehicle-Use-Our-Photo-Upload-Feature-">
            Upload photos
          </a>
        </span>{' '}
        of the items being delivered.{' '}
      </>
    ]
  },
  {
    title: 'Pay with ease',
    image: (
      <StaticImage
        src={Image4}
        placeholder="blurred"
        alt="Multiple payment methods"
      />
    ),
    points: [
      <>
        {' '}
        Pay with{' '}
        <span className="teal">
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/115002425133-Types-of-cashless-payment-option-for-food-shopping-jobs">
            GoGet credits
          </a>
        </span>
        .{' '}
      </>,
      <>
        {' '}
        Secure payment methods for food and shopping tasks with{' '}
        <span className="teal">
          <a href="https://gogetmy.zendesk.com/hc/en-us/articles/115002436934-How-to-use-cashless-payment-option-for-food-shopping-jobs-on-GoGet">
            GoGet Pay
          </a>
        </span>
        .
      </>,
      <>
        Pay with cash only for selected service types.
        <br />
        <br />
      </>
    ]
  }
];

export { howItWorksData };
